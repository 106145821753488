<template>
  <div class="login-container">
    <img src="../assets/img/logo.png" class="logo" alt="logo" :style="{ width:'149px'}" />
    <h2 :style="{ fontSize: '32px', marginTop: '24px' }">Create New Password</h2>
    <a-form class="login-form" @submit.prevent="changePassword" :model="formState">
      
      <label for="password" :style="{ color: '#ADB5BD', fontSize: '14px', margin: 0 }">Password</label>
      <a-form-item name="password" :rules="[{ required: true, message: 'Please input your password!' }]">
        <a-input-password v-model:value="formState.password" :style="{ height: '40px', background: '#343A40' }" placeHolder="Enter your Password"></a-input-password>
      </a-form-item>

      <label for="passwordConfirm" :style="{ color: '#ADB5BD', fontSize: '14px', margin: 0 }">Confirm Password</label>
      <a-form-item name="passwordConfirm" 
        :rules="[
          { required: true, message: 'Please confirm your password!' },
          { validator: confirmPasswordValidator }
        ]
      ">
        <a-input-password v-model:value="formState.passwordConfirm" :style="{ height: '40px', background: '#343A40' }" placeHolder="Confirm your Password"></a-input-password>
      </a-form-item>

      <a-form-item>
        <a-button
          :block="true"
          html-type="submit"
          :style="{ height: '40px', marginTop: '24px', background: '#F8DD4A', color: 'black' }"
          :loading="isLoading"
        >
          {{ isLoading ? 'Loading' : 'Change Password' }}
        </a-button>
        <div :style="{ color: '#a61d24' }">{{ message }}</div>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import loginService from '../services/login';

export default {
  data() {
    return {
      formState: {
        email: '',
        password: '',
        passwordConfirm: '',
        code: '',
        email1: '',
      },
      message: '',
      isLoading: false,
    };
  },
  mounted() {
    const { email, code } = this.$store.getters.forgotUser;
    if (email && code) {
      this.formState.email = email;
      this.formState.code = code;
    } else {
      // 이메일이나 코드가 없을 경우 처리
      this.$router.push('/forgot-password');
    }
  },
  methods: {
    confirmPasswordValidator(rule, value) {
      if (value !== this.formState.password) {
        return Promise.reject("Password doesn't match");
      } else {
        return Promise.resolve();
      }
    },
    async changePassword() {
      if (!this.formState.password || !this.formState.passwordConfirm) return;
      if (this.formState.password !== this.formState.passwordConfirm) {
        this.message = "Password doesn't match";
        return;
      }

      this.isLoading = true;
      const res = await loginService.changePassword(this.formState.email, this.formState.code, this.formState.password);
      this.isLoading = false;
      if (!res.r) {
        this.message = res && res.msg ? res.msg : 'There was a network error.\nPlease try again.';
        return;
      } else if (res.r) {
        this.$store.dispatch('setForgotUser', { email: '', code: '' });
        this.$router.push('/login');
      }
    },
  },
};
</script>

<style scoped>
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  margin-top: 244px;
}

.login-form {
  margin-top: 36px;
  width: 328px;
  display: flex;
  flex-direction: column;
}
</style>