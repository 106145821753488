<template>
  <div class="login-container">
    <img src="../assets/img/logo.png" class="logo" alt="logo" :style="{ width:'149px'}" />
    <h2 :style="{ fontSize: '32px', marginTop: '24px' }">OPSIS by Stinger Cloud</h2>
    <a-form class="login-form" @submit.prevent="login" :model="formState">
      <label for="email" :style="{ color: '#ADB5BD', fontSize: '14px', margin: 0 }">Email</label>
      <a-form-item name="email" :rules="[{ required: true, message: 'Please input your email!' }]">
        <a-input v-model:value="formState.email" :style="{ height: '40px', background: '#343A40' }" placeHolder="Enter your email"></a-input>
      </a-form-item>
      <label for="password" :style="{ color: '#ADB5BD', fontSize: '14px', margin: 0 }">Password</label>
      <a-form-item name="password" :rules="[{ required: true, message: 'Please input your password!' }]">
        <a-input-password v-model:value="formState.password" :style="{ height: '40px', background: '#343A40' }" placeHolder="Enter your password">
        </a-input-password>
      </a-form-item>
      <button type="button" @click="handleClickForgotPass" class="button1">Forgot Password?</button>
      <a-form-item>
        <a-button
          :block="true"
          html-type="submit"
          :style="{ height: '40px', marginTop: '24px', background: '#F8DD4A', color: 'black' }"
          :loading="isLoading"
        >
          {{ isLoading ? 'Loading' : 'Sign In' }}
        </a-button>
        <div :style="{ color: '#a61d24' }">{{ message }}</div>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import loginService from '../services/login';

export default {
  data() {
    return {
      formState: {
        email: '',
        password: '',
      },
      message: '',
      isLoading: false,
    };
  },
  methods: {
    async login() {
      if (!this.formState.email || !this.formState.password) return;

      this.isLoading = true;
      const res = await loginService.login(this.formState.email, this.formState.password);
      this.isLoading = false;
      if (!res.r) {
        this.message = res.message;
        return;
      }

      this.$store.dispatch('login', { user: res.member, token: res.token });
      this.$router.push('/');
    },

    handleClickForgotPass() {
      console.log('forgot password');
      this.$router.push('/forgot-password');
    }
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  margin-top: 244px;
}
.login-form {
  margin-top: 36px;
  width: 328px;
  display: flex;
  flex-direction: column;
}
.button1 {
  padding:1px 0px;
  margin-left:auto;
  text-decoration: underline;
  color: #F8DD4A;
}
</style>
