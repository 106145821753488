import { createStore } from 'vuex';
import loginService from '../services/login';

const store = createStore({
  state() {
    return {
      isLoggedIn: false,
      user: null,
      forgotUser: {
        email: '',
        code: '',
      },
    };
  },
  mutations: {
    login(state, { user, token }) {
      state.isLoggedIn = true;
      state.user = user;
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('isLoggedIn', 'true');
      localStorage.setItem('token', token);
    },
    logout(state) {
      state.isLoggedIn = false;
      state.user = null;
      localStorage.removeItem('user');
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('token');
    },
    setForgotUser(state, { email, code }) {
      state.forgotUser.email = email;
      state.forgotUser.code = code;
    },
  },
  actions: {
    login({ commit }, { user, token }) {
      commit('login', { user, token });
    },
    logout({ commit }) {
      commit('logout');
    },
    async isAuthorized({ commit }) {
      const token = localStorage.getItem('token');
      const res = await loginService.fetchIsLogin(token);
      if (res.r) {
        commit('login', { user: res.member, token: token });
        return true;
      } else {
        return false;
      }
    },
    setForgotUser({ commit }, { email, code }) {
      commit('setForgotUser', { email, code });
    },
  },
  getters: {
    isLoggedIn: (state) => state.isLoggedIn,
    user: (state) => state.user,
    forgotUser: (state) => state.forgotUser,
  },
});

export default store;
