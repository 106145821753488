import axios from './sticarAxios';

export default {
  fetchIsLogin(token) {
    return axios
      .get(`/member/islogin`, { params: { token } })
      .then((res) => res.data)
      .catch((e) => {
        throw e;
      });
  },
  login(username, password) {
    return axios
      .post(`/member/login`, { email: username, pass: password })
      .then((res) => res.data)
      .catch((e) => {
        throw e;
      });
  },
  authSendMail(email) {
    return axios
      .post(`/api/mail/request`, { email: email })
      .then((res) => res.data)
      .catch((e) => {
        throw e;
      });
  },
  compareAuthCode(email, code) {
    return axios
      .post(`/api/mail/auth`, { email: email, number: code })
      .then((res) => res.data)
      .catch((e) => {
        throw e;
      });
  },
  changePassword(email, code, password) {
    return axios
      .post(`/api/mail/password/change`, { email: email, number: code, pass: password })
      .then((res) => res.data)
      .catch((e) => {
        throw e;
      });
  }
};
