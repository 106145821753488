<template>
  <div class="login-container">
    <img src="../assets/img/logo.png" class="logo" alt="logo" :style="{ width:'149px'}" />
    <h2 :style="{ fontSize: '32px', marginTop: '24px' }">Reset Password</h2>
    <a-form class="login-form" @submit.prevent="forgotPassword" :model="formState">
      <label for="email" :style="{ color: '#ADB5BD', fontSize: '14px', margin: 0 }">Email</label>
      <a-form-item name="email" 
        :rules="[
          { required: true, message: 'Please input your email!' },
          { type: 'email', message: 'Please enter a valid email address.' },
        ]"
      >
        <a-input v-model:value="formState.email" :style="{ height: '40px', background: '#343A40' }" placeHolder="Enter your email"></a-input>
      </a-form-item>

      
      <label v-if="this.step===2" for="code" :style="{ color: '#ADB5BD', fontSize: '14px', margin: 0 }">Authentication Number</label>
      <a-form-item name="code" 
        v-if="this.step===2"
        :rules="[
          { required: true, message: 'Please input your Authentication Number!' },
          { pattern: /^\d{4}$/, message: 'Please enter a 4-digit number.' },
        ]"
      >
        <a-input v-model:value="formState.code" :style="{ height: '40px', background: '#343A40' }" placeHolder="Enter your number"></a-input>
      </a-form-item>
      <a-form-item>
        <a-button
          :block="true"
          html-type="submit"
          :style="{ height: '40px', marginTop: '24px', background: '#F8DD4A', color: 'black' }"
          :loading="isLoading"
        >
          {{ isLoading ? loadingText : 'Next' }}
        </a-button>
        <div :style="{ color: '#a61d24' }">{{ message }}</div>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import loginService from '../services/login';

export default {
  data() {
    return {
      step: 1,
      formState: {
        email: '',
        code: '',
      },
      message: '',
      isLoading: false,
      loadingText: 'Loading',
    };
  },
  methods: {
    async forgotPassword() {
      if(this.step === 1) 
        this.sendEmail();
      else if(this.step === 2)
        this.compareAuthCode();
    },

    async compareAuthCode() {
      if (!this.formState.email || !this.formState.code) return;
      if (!this.formState.code.match(/^\d{4}$/)) return;

      this.loadingText ='Verifying Authentication Number';
      this.isLoading = true;
      const res = await loginService.compareAuthCode(this.formState.email, this.formState.code);
      this.isLoading = false;
      
      if (!res.r) {
        this.message = res.msg;
        return;
      } else if(res && res.r) {
        this.$store.dispatch('setForgotUser', { email: this.formState.email, code: this.formState.code });
        this.$router.push({ path: '/change-password'});
      }
    },
    async sendEmail() {
      if (!this.formState.email) return;

      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(this.formState.email)) return;

      this.loadingText = 'Sending';
      this.isLoading = true;

      const res = await loginService.authSendMail(this.formState.email);
      this.isLoading = false;

      if (!res.r) {
        this.message = res.msg;
        return;
      } else if(res && res.r) {
        this.step = 2;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  margin-top: 244px;
}
.login-form {
  margin-top: 36px;
  width: 328px;
  display: flex;
  flex-direction: column;
}
.button1 {
  padding:1px 0px;
  margin-left:auto;
  text-decoration: underline;
  color: #F8DD4A;
}
</style>
